<template>
  <div class="justify-content-start view-container">
    <HeaderResidential />
  </div>
  <div class="justify-content-start h-100 view-container">
    <div class="mt-4">
      <div class="row">
        <span class="title">{{$t('directory.directory')}}</span>

        <div class="mt-4 ui form">
          <div id="editor" v-html="textDirectory"></div>

          <div class="mt-3 row">
            <div class="col-md-1">
              <Button
                :text="$t('buttons.save')"
                color="primary"
                @clicked="saveDirectory"
                :appearance="{ height: '28px' }"
                class="d-inline"
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@toast-ui/editor/dist/toastui-editor.css'
import '@toast-ui/editor/dist/i18n/es-es'
import Editor from '@toast-ui/editor'
import { mapGetters } from 'vuex'
import HeaderResidential from '../../components/ui/HeaderResidential'
import Button from '../../components/form/Button'
import { getDirectoryByResidential, createOrUpdateDirectory } from '../../api/directory'

export default {
  components: {
    HeaderResidential,
    Button
  },
  data () {
    return {
      editor: null,
      textDirectory: ''
    }
  },
  computed: {
    ...mapGetters('residential', ['activeResidential'])
  },
  created () {
    this.getDirectory()
  },
  methods: {
    setEditor () {
      this.editor = new Editor({
        el: document.querySelector('#editor'),
        height: '400px',
        initialEditType: 'WYSIWYG',
        previewStyle: 'vertical',
        hideModeSwitch: true,
        language: 'es'
      })
    },

    getDirectory () {
      this.$store.dispatch('loading/setLoading', true)
      getDirectoryByResidential(this.activeResidential.residentialId)
        .then(({ data }) => {
          this.textDirectory = data.description ? data.description : ''
        })
        .catch(() => {
          this.textDirectory = ''
        })
        .finally(() => {
          this.setEditor()
          this.$store.dispatch('loading/setLoading', false)
        })
    },

    saveDirectory () {
      let description = document.getElementsByClassName('toastui-editor-contents')[1].innerHTML
      description = description.replaceAll('<p><br></p>', '<p></p>')
      this.$store.dispatch('loading/setLoading', true)
      createOrUpdateDirectory({
        residentialId: this.activeResidential.residentialId, description
      })
        .then(() => {
          this.$swal(this.$t('directory.modified'), '', 'success')
        })
        .catch(() => {
          this.$swal(this.$t('errors.tryLater'), '', 'error')
        })
        .finally(() => {
          this.$store.dispatch('loading/setLoading', false)
        })
    }
  }
}
</script>

<style scoped>
  .title {
    font-size: 17px;
    color: #436f98;
    font-weight: bold;
  }

  .toastui-editor-toolbar-icons.code {
    display: none !important
  }
</style>
