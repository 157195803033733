import apiApp from './config/apiApp'

function getDirectoryByResidential (residentialId) {
  return apiApp.get(`/residential/${residentialId}/directory`)
}

function createOrUpdateDirectory (data) {
  return apiApp.post('/directory', data)
}

export {
  getDirectoryByResidential,
  createOrUpdateDirectory
}
